import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TematicaNuntiiPage = () => (
  <Layout>
    <SEO title="Nuntă cu tematică" />
    <div className="drawer article-content">
      <h1 className="article-title">Nuntă cu tematică</h1>

      <p>
        V-ați ales deja localul sau acum urmează și incepeți să vă gânditi la
        detaliile nunții, astfel încercati să conturați o tematică.
      </p>
      <p>
        De obicei, tematica nunții ilustrează povestea voastră: poate cum v-ații
        cunoscut, ce pasiuni aveți sau orice vă definește pe voi și povestea
        voastră de dragoste. Cadrul este creat astfel încât să spună povestea
        voastră.
      </p>
      <p>
        Puteti avea o nuntă cu tematică romantică, vintage, boho, rustică, fancy
        și pornind de aici să alegeți elemente decorative potrivite. Sau puteți
        alege o tematică mai “precisă” (mai bine definită), dacă vă reprezintă:
      </p>
      <div>
        <div>
          <h3 className="paragh-title">Tematica marină </h3>
          <p>
            Este foarte întalnită (și ușor de aplicat dacă nunta ta este chiar
            la malul mării), însă și iubitorii de mare pot alege tema aceasta.
            Inlocuiești florile cu vaze umplute cu nisip și câteva scoici,
            folosești nuanțe de albastru, poți decora și invitatiile, numerele
            de masă și place-cardurile cu mare, valuri, soare.
          </p>
        </div>
        <img src="https://images.unsplash.com/photo-1515232389446-a17ce9ca7434?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1666&q=80" />

        <div>
          <h3 className="paragh-title">
            Pasiunea pentru călătorii poate contura decoruri frumoase
          </h3>
          <p>
            {" "}
            În loc de clasicele numere de masă poți alege numele unei țări
            vizitate de voi sau a unui obiectiv turistic. Invitațiile pot fi sub
            forma unor bilete de avion, bilete de tren sau chiar o valiză. In
            loc de guestbook puteți alege o valiză vintage în care invitații vă
            pot lăsa mesaj/sfaturi sau guestbook-ul poate fi chiar o hartă sau
            un glob pământesc, pe care invitații pot scrie mesaje.
          </p>
        </div>

        <div>
          <h3 className="paragh-title">Gatsby Party </h3>
          <p>
            {" "}
            Pornești de la ideea că vrei o petrecere mai fancy și adaugi
            direcția de Gatsby Party, care te duce cu gândul la petrecerile din
            anii ’20. Poți intra în atmosferă rugând invitații să se îmbrace
            tematic sau le poți oferi, în loc de mărturii, pălării și celebrele
            bentițe cu pană. Decorurile trebuie să iasă în evidență, așadar vei
            folosi auriu și combinații de alb și negru. Florile le poți înlocui
            cu pene mari, iar la baza vazelor poți crea aranjamente folosindu-te
            de mărgele. Poți crea un photocorner autentic, creându-ți un panou
            cu poze alb-negru, din perioada respectivă. Mesajele pentru
            guestbook pot fi scrise de invitați direct la mașina de scris.
          </p>
        </div>

        <div>
          <h3 className="paragh-title">
            Pasiunea pentru cărți poate fi intregrată în poveste
          </h3>
          <p>
            {" "}
            În loc de numere de masă poți folosi titluri de cărți,
            aranajamentele florale pot fi puse peste cărți, iar pentru un aer
            mai boem poți înlocui clasicele vaze cu ceainice sau borcănele
            draguțe.
          </p>
        </div>
        <img src="https://images.unsplash.com/photo-1519167758481-83f550bb49b3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1666&q=80" />

        <div>
          <h3 className="paragh-title">
            O tematică tot mai folosită este inspirată din povești, mai ales din
            “Frumoasa și Bestia”
          </h3>
          <p>
            Iar nunțile fie sunt în culorile din celebra poveste, albastru si
            galben, fie folosesc elementele cunoscute: ceainicul, trandafirul
            sau chiar costumațiile.
          </p>
        </div>
      </div>

      <p>
        {" "}
        Chiar dacă nu ai in minte o temă specifică, te poți gândi la o tematică
        “mai generală”, însă este important sa pornești de la o idee, iți va fi
        mult mai ușor să iei restul alegerilor.
      </p>

      <p>
        De exemplu, dacă nunta ta este toama si tot ce știi sigur este ca ai
        vrea flori “de sezon/de toamnă”, atunci poți alege celalte decorațiuni
        in funcție de culori. Sigur că cele mai întalnite sunt roșu sau
        portocaliu, însă poți alege și burgundy sau verde smarald sau chiar un
        galben mai inchis (honey).
      </p>

      <p>Sunt micile detalii care fac diferența.</p>
      <img src="https://images.unsplash.com/photo-1507915977619-6ccfe8003ae6?ixlib=rb-1.2.1&auto=format&fit=crop&w=560&q=80" />

      <div className="nav-section">
        <Link to="/primiipasi">◀ Primii pasi</Link>
        <Link to="/florile">▶ Cum alegem florile ?</Link>
      </div>
    </div>
  </Layout>
)

export default TematicaNuntiiPage
